import { useCheckoutStore } from "./checkout";

export const useCartStore = defineStore("cart", {
  state: () => ({
    init: false,
    loading: true,
    items: [],
    total: 0,
    subtotal: 0,
    taxes: [],
    uuid: "",
    shippingFee: 0
  }),

  actions: {
    fetchCartItems(cb = null) {
      const nuxtApp = useNuxtApp();
      const $config = useRuntimeConfig().public;

      this.loading = true;
      this.init = true;
      nuxtApp.$api("api/front/cart", {
        headers: {
          "X-Cart": nuxtApp.$cookies.get($config.CART_COOKIE_NAME),
        },
      })
        .then(({ data }) => {
          this.loading = false;

          this.items = data.items;
          this.total = data.total;
          this.subtotal = data.subtotal;
          this.taxes = data.taxes;
          this.uuid = data.uuid;
          this.shippingFee = data.fee

          if (cb) {
            cb()
          }
        })
        .catch((e) => {
          this.loading = false;

          console.error("[cart] load error", e);
        });
    },
    addCartItem({ uuid, cb }) {
      const nuxtApp = useNuxtApp();
      const $config = useRuntimeConfig().public;

      const cartItem = this.items.find((item) => item.uuid === uuid);

      if (!cartItem) {
        console.log(nuxtApp.$cookies.get($config.CART_COOKIE_NAME))
        return nuxtApp.$api(
          "api/front/cart/items",
          {
            method: 'post',
            body: { uuid },
            headers: {
              "X-Cart": nuxtApp.$cookies.get($config.CART_COOKIE_NAME),
            },
          },
        )
          .then(() => {
            if (cb) {
              cb();
            }
            this.fetchCartItems();
          });
      }
    },
    async removeCartItem(uuid, cb) {
      const nuxtApp = useNuxtApp();
      const $config = useRuntimeConfig().public;

      const cartItem = this.items.find((item) => item.uuid === uuid);

      if (cartItem) {
        this.loading = true;
        await nuxtApp.$api(`api/front/cart/items/${uuid}`, {
          method: 'delete',
          headers: {
            "X-Cart": nuxtApp.$cookies.get($config.CART_COOKIE_NAME),
          },
        })

        this.fetchCartItems(cb);

      }
    },
    checkout() {
      const nuxtApp = useNuxtApp();
      const $config = useRuntimeConfig().public;

      return nuxtApp.$api(
        "api/front/cart/check-out",
        {
          headers: {
            "X-Cart": nuxtApp.$cookies.get($config.CART_COOKIE_NAME),
          },
        }
      )
        .then(({ data }) => {
          useCheckoutStore.setSalesOrder(data);
        })
        .catch(() => {
          console.error("[cart] checkout error");
        });
    },
  },

  getters: {
    getUUID: (state) => state.uuid,
    getLoading: (state) => state.loading,
    getItems: (state) => state.items,
    getTotal: (state) => state.total,
    getTaxes: (state) => state.taxes,
    getSubTotal: (state) => state.subtotal,
    getNumberOfItems: (state) => (state.items ? state.items.length : 0),
    getShippingFee: state => state.shippingFee
  },
});